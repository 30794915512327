(function ($) {
	$(window).load(function () {
		// owl-carousel
		$(".owl-carousel:not(.slider)").owlCarousel({
			items: 1,
			loop: false,

			rewind: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 3000,

			// speed
			smartSpeed: 500,

			// animation
			animateIn: "fadeIn",
			animateOut: "fadeOut",

			// navigation
			nav: true,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: false,
		});

		// slider
		$(".slider").owlCarousel({
			items: 1,
			margin: 15,
			loop: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: true,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: true,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
				},
				1200: {
					items: 3,
				},
			},
		});

		// form-sent
		setTimeout(() => {
			var locationSearch = "" + document.location.search;
			if (
				(-1 === locationSearch.indexOf("form[sent]") &&
					-1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
				-1 === document.location.hash.indexOf("#form")
			) {
				return;
			}

			var $headerHeight = $(".header");
			var $message = $(".form-success-message");

			if (!$headerHeight.length || !$message.length) {
				return;
			}

			var currentScrollTop = $("html").scrollTop();
			var newScrollTop =
				$message.offset().top - $headerHeight.outerHeight() - 30;
			if (newScrollTop >= currentScrollTop) {
				return;
			}

			$("html, body").animate({
				scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
			});
		}, 500);
	});

	$(document).ready(function () {
		// sticky
		require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

		// fancybox
		$("a[data-fancybox]").fancybox({
			buttons: [
				//"zoom",
				//"share",
				//"slideShow",
				//"fullScreen",
				//"download",
				"thumbs",
				"close",
			],
		});

		// make kasabon in smooth layout sticky
		require("../../vendor/w3media/framework/assets/js/V2/hc-sticky");
		$('.w3media-booking-receipt-widget').hcSticky({
			stickTo: '.content-section',
			top: 75,
			bottom: 50,
			responsive: {
				992: {
					disable: true
				}
			}
		});

		// menu toggle btn
		$('.menu-toggle').on('click', function(e) {
			$(this).toggleClass('clicked');
		});

		// close menu on mobile
		$('#hamburger_menu').on('click', (e) => {
			if ( $(e.target).prop("tagName") == 'NAV' ) {
				$('#hamburger_menu').toggleClass('show');
				$('.menu-toggle').toggleClass('clicked');
			}
		});

		// scroll-to-content
		$(".js-3w-scroll-to-content").on("click", function (event) {
			event.preventDefault();
			var identifier = $(this).attr("data-target");
			var $element = $(identifier);

			if ($element) {
				$("html, body").animate({ scrollTop: $element.offset().top }, 0);
			}
		});

		// faq fix
		let faqlinks = document.querySelectorAll(".faq-category a");
		faqlinks.forEach((link) => {
			if (link.id) {
				link.id = "";
				link.href = "#";
			}
		});

		// prevent scrolling on #TommyBookingSupport page
		$(window).on("hashchange", function (e) {
			if ($("#TommyBookingSupport").length > 0) {
				e.preventDefault();

				// disable scrolling
				$("body").css("overflow", "hidden");

				// set current scroll position
				$("html, body").animate({
					scrollTop: $("#TommyBookingSupport").offset().top - 120,
				});

				// enable scrolling
				setTimeout(() => {
					$("body").css("overflow", "auto");
				}, 10);
			}
		});

		// Clone all <li> elements
		var clonedItems = $("ul.news-ticker li").clone();
		// Append the cloned items to another <ul> with id="cloned-list"
		if ( $("ul.news-ticker li").length < 4 ) {
			$("ul.news-ticker").append(clonedItems);
			clonedItems = $("ul.news-ticker li:nth-child(-n+3)").clone();
			$("ul.news-ticker").append(clonedItems);
		} else {
			$("ul.news-ticker").append(clonedItems);
		}
	});
})(jQuery);
